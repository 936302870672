angular.module('LeasePilot').directive('duplicateCompanyModal', [
  '$mdDialog',
  '$http',
  function($mdDialog, $http) {
    return {
      restrict: 'E',
      templateUrl: 'duplicate_modal.html',
      link: function($scope, element, attrs) {
        document.getElementById('company-name').focus();
        $scope.companyName;
        $scope.copyData = false;
        var companyId = attrs.companyId || undefined;

        $scope.disabled = function() {
          var isValid =
            $scope.form != null &&
            $scope.form.company_name != null &&
            $scope.form.company_name.$valid;
          if (($scope.companyName && $scope.actionStarted) || !isValid)
            return true;
          return !$scope.companyName;
        };

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.duplicateCompany = function() {
          $scope.actionStarted = true;
          $http({
            method: 'POST',
            url: '/duplicate_company',
            data: {
              company_id: companyId,
              company_name: $scope.companyName,
              copy_data: $scope.copyData,
            },
          }).then(
            function(response) {
              $mdDialog.cancel();
              window.location.reload();
            },
            function(error) {
              console.log('errors: ', error.data.errors);
              $scope.error = error.data.message;
              $scope.actionStarted = false;
            },
          );
        };
      },
    };
  },
]);
